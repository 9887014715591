//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { vali_exist } from '@/utils/validator'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import editor from '@/views/fileStream/documentManage/editor.vue'
import upload from '@/views/fileStream/documentManage/upload.vue'
import selectPersonAndDepart, {
  getUserListMsg,
  dealResult
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import { documentBillAdd, documentBillDetail, documentBillEdit } from '@/api/modular/fileStream/documentManage'
export default {
  components: { editor, upload, OpenData, selectPersonDialog },
  props: {
    officialId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      labelCol: {
        sm: { span: 5 },
      },
      wrapperCol: {
        sm: { span: 19 },
      },
      form: this.$form.createForm(this),
      flow_secrecy_degree: [],
      flow_urgency_degree: [],
      flow_file_type: [],
      fileIds: [],
      content: '',
      ngdw_list: [],
      hgdw_list: [],
      wbhgdw_list: [], // 外部會稿單位
      checkIds: [],
      mainIds: [],
      sendIds: [],
      handler: vali_exist,
    }
  },
  watch: {
    ngdw_list(v) {
      this.form.setFieldsValue({ ngdw_list: v.toString() })
    },
    hgdw_list(v) {
      this.form.setFieldsValue({ hgdw_list: v.toString() })
    },
    wbhgdw_list(v) {
      this.form.setFieldsValue({ wbhgdw_list: v.toString() })
    },
    checkIds(v) {
      this.form.setFieldsValue({ checkIds: v.toString() })
    },
    mainIds(v) {
      this.form.setFieldsValue({ mainIds: v.toString() })
    },
    sendIds(v) {
      this.form.setFieldsValue({ sendIds: v.toString() })
    },
    officialId() {
      this.get_info()
    }
  },
  created() {
    this.getDict()
  },
  mounted() {
    this.get_info()
  },
  methods: {
    showSelctDialog(key, index) {
      this.$refs.selectPersonDialog.open({ key, index })
    },
    objectEquail(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    },
    selectTodoMsg({ type, data: { key, index, result } }) {
      if (type === 'book') {
        this.choosePerson(key, index)
      } else if (type === 'label') {
        if (key === 'list') {
          result.forEach(ele => {
            if (!this[key][index].val.some(j => this.objectEquail(j, ele))) {
              this[key][index].val.push(ele)
            }
          })
        } else {
          result.forEach(element => {
            if (!this[key].some(j => this.objectEquail(j, element))) {
              this[key].push(element)
            }
          })
        }
      }
    },
    get_info() {
      this.form_id = this.officialId
      const id = this.form_id
      if (id) {
        documentBillDetail({ id }).then((res) => {
          const { get_arr } = this
          const {
            documentNumber,
            outDraftDesc,
            name,
            urgencyDegree,
            secrecyDegree,
            draftId,
            fileType,
            documentFileList,
            content,
            meetId,
            outDraftId
          } = res.data
          const form = {
            biaoti: name,
            jjcd: urgencyDegree ? String(urgencyDegree) : '',
            miji: secrecyDegree ? String(secrecyDegree) : '',
            userId: draftId,
            xzfn: fileType ? String(fileType) : '',
            fileIds: documentFileList,
            content,
            hgdw_list: meetId,
            wbhgdw_list: outDraftId,
            checkIds: res.data.checkIds,
            mainIds: res.data.mainIds,
            sendIds: res.data.sendIds,
            documentNumber,
            outDraftDesc
          }
          //
          this.form.setFieldsValue(this.delEmptKey(form))
          //
          this.ngdw_list = this.formatDraftId(draftId)
          this.hgdw_list = this.formatDraftId(meetId)
          this.wbhgdw_list = this.formatDraftId(outDraftId)
          this.checkIds = this.formatDraftId(res.data.checkIds)
          this.mainIds = this.formatDraftId(res.data.mainIds)
          this.sendIds = this.formatDraftId(res.data.sendIds)
          //
          // this.$refs.editor.msg = content
          this.fileIds = documentFileList
        })
      }
    },
    /**
     * 獲取字典數據
     */
    getDict() {
      sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
        this.flow_secrecy_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
        this.flow_urgency_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
        this.flow_file_type = res.data
      })
    },

    clearMessage() {
      this.clear_form()
      this.empty()
      // this.$refs.editor.msg = ''
    },

    clear_form() {
      this.form.resetFields()
      this.ngdw_list = []
      this.hgdw_list = []
      this.wbhgdw_list = []
      this.checkIds = []
      this.mainIds = []
      this.sendIds = []
    },
    empty() {
      this.$refs.upload.empty()
    },
    selectPersonAndPart(list, index) {
      const { selectedOpenUserIds,
        selectedCorpGroupUserIds,
        selectedDepartmentIds,
        selectedCorpGroupDepartmentIds
      } = getUserListMsg(list, index, this[list])
        selectPersonAndDepart({
        selectedOpenUserIds,
        selectedCorpGroupDepartmentIds,
        selectedDepartmentIds,
        selectedCorpGroupUserIds
      })
      .then(res => {
        const { result, resPart } = dealResult(res)
        this.$set(this, list, [ ...result, ...resPart ])
      })
      .catch(err => {
        console.log(err)
      })
    },
    choosePerson(list, index) {
      this.selectPersonAndPart(list, index)
    },
    choose_Persons(list, index) {
      this.selectPersonAndPart(list, index)
    },
    delEmptKey(obj) {
      const objCopy = { ...obj } 
      const keys = Object.keys(objCopy)
      for (const index of keys) {
        if (!objCopy[index]) delete objCopy[index]
      }      
      return objCopy
    },
    handleSubmit(e, status) {
      const { filter, content, fileIds, ngdw_list, hgdw_list, wbhgdw_list, checkIds, mainIds, sendIds } = this
      //
      let func = documentBillAdd
      const id = this.form_id
      if (id) {
        func = documentBillEdit
      }
      //
      e && e.preventDefault()
      this.form.validateFields((err, values) => {
        console.log('Received values of form: ', values)
        if (!err) {
          const obj = {
            name: values.biaoti,
            urgencyDegree: Number(values.jjcd) || '',
            secrecyDegree: Number(values.miji) || '',
            draftId: filter(ngdw_list),
            fileType: Number(values.xzfn) || '',
            fileIds,
            content,
            documentNumber:values.documentNumber,
            outDraftDesc:values.outDraftDesc,
            meetId: filter(hgdw_list),
            outDraftId: filter(wbhgdw_list),
            documentType: 2,
            checkIds: filter(checkIds),
            mainIds: filter(mainIds),
            sendIds: filter(sendIds),
            status
          }        
          //
          if (id) {
            obj.id = id
          }
          //      
          func(this.delEmptKey(obj)).then((res) => {
            if (!res.success) {
              return
            }
            this.$message.success(res.message)
            this.$emit('close')
            //
            // this.$parent.change = 1
            // this.$router.replace({ path: '/fileStreamDocumentSend' })
          })
        }
      })
    },
    formatDraftId(id) {
      if (!id) return []
      return JSON.parse(id).map(j => ({ ...j, id: j.userId || j.departmentId }))
    },
    getIdKey(type) {
      return type === 'departmentName' ? 'departmentId' : 'userId'
    },
    filter(ids) {
      return JSON.stringify(ids.map(({ corpId, userId, id, type }) => ({
        [this.getIdKey(type)]: userId || id,
        corpId,
        type
      })))
    },
    get_arr(ids) {
      const arr = ids.split(',')
      const data = []
      arr.forEach((element) => {
        data.push({ userId: element })
      })
      return data
    },
  },
}
